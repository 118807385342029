const useDynamicListBlock = (blockElement: HTMLElement): void => {
  const pagesToShow: number = parseInt((blockElement.firstElementChild as HTMLDivElement).dataset.pagesToShow!);
  const pages = Array.from(blockElement.querySelectorAll('.dynamic-list-block__page')) as HTMLLIElement[];
  const filterButtons = blockElement.querySelectorAll('.dynamic-list-block__filter-button') as NodeListOf<HTMLButtonElement>;
  const showMoreContainer: HTMLDivElement | null = blockElement.querySelector('.dynamic-list-block__show-more');
  const showMoreButton: HTMLButtonElement | null = blockElement.querySelector('.dynamic-list-block__show-more-button');

  /* Show and hide pages */

  // Show all pages and "Show more" button if needed
  const showAllPages = (): void => {
    pages.forEach((page, index) => {
      if (index < pagesToShow && page.hidden) {
        page.hidden = false;
      }

      if (page.classList.contains('dynamic-list-block__page--filtered')) {
        page.classList.remove('dynamic-list-block__page--filtered');
      }
    });

    // Show "Show more" button if needed
    if (pages.length > pagesToShow && showMoreContainer && showMoreContainer.hidden) {
      showMoreContainer.hidden = false;
    }
  };

  // Hide all pages and remove "Show more" button
  const hideAllPages = (): void => {
    pages.forEach((page) => {
      if (!page.hidden) {
        page.hidden = true;
      }

      if (page.classList.contains('dynamic-list-block__page--filtered')) {
        page.classList.remove('dynamic-list-block__page--filtered');
      }
    });

    // Hide "Show more" button
    if (showMoreContainer && !showMoreContainer.hidden) {
      showMoreContainer.hidden = true;
    }
  };

  const showMorePages = (pages: HTMLLIElement[], maxIndexToShow: number, firstShownPageIndex: number): void => {
    pages.forEach((page, index) => {
      if (page.hidden && index <= maxIndexToShow) {
        page.hidden = false;

        // Fade in animation
        page.classList.add('dynamic-list-block__page--fade-in');

        setTimeout(() => {
          page.classList.remove('dynamic-list-block__page--fade-in');
        }, 100);
      }
    });

    // Hide "Show more" button if there are no more hidden pages
    const hiddenPages = pages.filter((page) => page.hidden);
    if (hiddenPages.length === 0 && showMoreContainer && !showMoreContainer.hidden) {
      showMoreContainer.hidden = true;
    }

    // Set focus to the first shown page
    const firstShownPage: HTMLLIElement | null = pages[firstShownPageIndex];
    if (firstShownPage) {
      const firstShownPageLink = firstShownPage.querySelector('a') as HTMLAnchorElement;
      firstShownPageLink.focus();
    }
  };

  const showPagesWithSelectedCategories = (selectedCategoryIds: number[]) => {
    const pagesWithCategories = pages.filter((page) => page.firstElementChild!.hasAttribute('data-category-ids'));
    const pagesWithCategoriesToShow: HTMLElement[] = [];

    pagesWithCategories.forEach((page) => {
      const pageArticle = page.firstElementChild as HTMLElement;
      const pageCategoryIds: number[] = JSON.parse(pageArticle.dataset.categoryIds!);

      // Show pages that match the selected categories
      if (pageCategoryIds.some((id) => selectedCategoryIds.includes(id))) {
        pagesWithCategoriesToShow.push(page);
      }

      // Hide all selected pages initially
      page.hidden = true;

      // Show as many pages as pagesToShow
      pagesWithCategoriesToShow.forEach((page, index) => {
        page.classList.add('dynamic-list-block__page--filtered');

        if (index < pagesToShow) {
          page.hidden = false;
        }
      });
    });
  };

  /* Handle events */

  const handleShowMoreEvent = (): void => {
    const visiblePages = pages.filter((page) => !page.hidden);
    const lastVisiblePageIndex = visiblePages.length - 1;
    const maxIndexToShow = lastVisiblePageIndex + pagesToShow;
    const filteredPages = pages.filter((page) => page.classList.contains('dynamic-list-block__page--filtered'));

    if (filteredPages.length > 0) {
      showMorePages(filteredPages, maxIndexToShow, lastVisiblePageIndex + 1);
    } else {
      showMorePages(pages, maxIndexToShow, lastVisiblePageIndex + 1);
    }
  };

  const handleFilterEvent = (event: MouseEvent): void => {
    const filterButton = event.target as HTMLButtonElement;
    const selectedCategoryIds: number[] = [];

    filterButton.classList.toggle('button--active');

    hideAllPages();

    // Store selected category id:s
    const selectedFilterButtons = blockElement.querySelectorAll('.button--filter.button--active') as NodeListOf<HTMLButtonElement>;
    selectedFilterButtons.forEach((button) => {
      selectedCategoryIds.push(parseInt(button.parentElement!.dataset.categoryId!));
    });

    // Show pages based on selected category id:s
    if (selectedCategoryIds.length > 0) {
      showPagesWithSelectedCategories(selectedCategoryIds);
    } else {
      showAllPages();
    }

    // Show "Show more" button if filtered pages are more than pages to show
    const filteredPages = blockElement.querySelectorAll('.dynamic-list-block__page--filtered') as NodeListOf<HTMLLIElement>;
    if (filteredPages.length > pagesToShow && showMoreContainer) {
      showMoreContainer.hidden = false;
    }
  };

  /* Initializing and adding event listeners */
  const initialize = (): void => {
    if (showMoreButton) {
      showMoreButton.addEventListener('click', handleShowMoreEvent);
    }

    if (filterButtons.length > 0) {
      filterButtons.forEach((button) => {
        button.addEventListener('click', handleFilterEvent);
      });
    }
  };

  initialize();
};

document.addEventListener('DOMContentLoaded', () => {
  const dynamicListBlockElements = document.querySelectorAll('.dynamic-list-block') as NodeListOf<HTMLElement>;

  if (dynamicListBlockElements.length > 0) {
    dynamicListBlockElements.forEach((blockElement) => {
      useDynamicListBlock(blockElement);
    });
  }
});