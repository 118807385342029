export const getParentsOfTypeUntilClass = (element, tagName, className) => {
  const parents = [];
  let currentElement = element.parentElement;

  while (currentElement && !currentElement.classList.contains(className)) {
    if (currentElement.tagName.toLowerCase() === tagName.toLowerCase()) {
      parents.push(currentElement);
    }
    currentElement = currentElement.parentElement;
  }

  return parents;
};